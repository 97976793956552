import common from "@/common";

export default [
  {
    name: 'companyName',
    label: 'common.company',
    type: 'text',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityName',
    label: 'common.facility',
    type: 'text',
    slot: 'facility',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'floorName',
    label: 'common.floor',
    type: 'text',
    slot: 'floor',
    edit_disable: true
  },
  {
    name: 'roomName',
    label: 'common.room',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'bedNumber',
    label: 'common.bed',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'memberCode',
    label: 'member.memberCode',
    type: 'text',
    edit_show: true,
    edit_disable: true
  },
  // {
  //   name: 'refCode',
  //   label: 'member.refCode',
  //   type: 'text',
  //   // rule: 'required|max: 20',
  //   edit_disable: true
  // },
  {
    name: 'chineseName',
    label: 'member.chineseName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'englishName',
    label: 'member.englishName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'rfidTags',
    label: 'geo_fencing.rfids',
    rule: 'required',
    type: 'slot',
    validate_in_slot: true,
    slot: 'inputs',
    slot_config: {
      name: 'rfid',
      label: 'geo_fencing.rfids',
      rule: 'required|length:24',
      type: 'text',
      min_count: 1,
      max_count: 99,
      input_class: "text-monospace",
      check_duplicate: true,
    },
  }

]
